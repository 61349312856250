<template>
  <b-modal
      id="confirmation-modal"
      scrollable
      no-close-on-backdrop
      :hide-footer="conData && conData.waiting"
      hide-header-close
      centered
      ref="confirmation-modal">
    <template v-slot:modal-title>
      <span v-if="conData && conData.waiting">Waiting for confirmation</span>
      <span class="mb-0 text-danger" v-else-if="conData && conData.modalType === 'error'">Transaction failed</span>
      <span class="mb-0" v-else-if="conData && conData.modalType === 'success'">Transaction submitted</span>
      <span class="mb-0" v-else-if="conData">{{ conData.title }}</span>
      <span class="modal-close-icon" clickable v-on:click="hideModal">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5.63672" y="16.9502" width="16" height="2" rx="1" transform="rotate(-45 5.63672 16.9502)" fill="#B5B5C3"/>
        <rect x="7.05078" y="5.63574" width="16" height="2" rx="1" transform="rotate(45 7.05078 5.63574)" fill="#B5B5C3"/>
      </svg>
    </span>
    </template>
    <b-overlay :show="conData && conData.waiting"
               :variant="'transparent'"
               :opacity="'0.25'"
               :blur="'1px'"
               rounded="sm"
               v-if="conData">
      <p class="mt-2 mb-7 text-danger" v-if="conData.modalType === 'error' && conData.errorMessage">{{ conData.errorMessage }}</p>
      <p class="mt-2 mb-7 text-success" v-if="conData.modalType === 'success' && conData.successMessage">{{ conData.successMessage }}</p>
      <b-row align-v="center" v-if="conData.pool" no-gutters align-h="between">
        <b-col class="jumbotron">
            <div class="caption">{{ conData['pool']['from']['title'] }}</div>
            <b-form-row>
              <b-col md="auto" class="w-auto">
                <div >{{ conData['pool']['from']['value'] }}</div>
              </b-col>
              <b-col>
                <div class="text-primary">{{ conData['pool']['from']['name'].toUpperCase() }}</div>
              </b-col>
            </b-form-row>
        </b-col>
        <b-col md="auto" class="text-center my-3 w-auto">
          <svg width="15" height="16" class="mx-2" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.75 9.33333H0.75L7.41667 16V12H12.75V9.33333Z" fill="#D6D6E0"/>
            <path d="M7.41667 0V4H2.08333V6.66667H14.0833L7.41667 0Z" fill="#D6D6E0"/>
          </svg>
        </b-col>
        <b-col v-if="conData.pool" class="jumbotron">
            <p class="caption">{{ conData.pool.to.title }}</p>
            <b-form-row>
              <b-col md="auto" class="w-auto">
                <div >{{ conData.pool.to.value }}</div>
              </b-col>
              <b-col>
                <div class="text-primary">{{ conData.pool.to.name.toUpperCase() }}</div>
              </b-col>
            </b-form-row>
        </b-col>
      </b-row>
      <b-table small borderless :items="conData.listData" :fields="fields" thead-class="d-none" class="mt-4 mb-0">
        <template v-slot:cell(name)="{value}" class="p-0">
          <span class="caption">{{ value }}</span>
        </template>
        <template v-slot:cell(data)="{item}">
          <span class="mr-3">{{ item.data }}</span><span class="caption">{{ item.dataText }}</span>
        </template>
      </b-table>
      <p class="mt-5 mb-0" v-if="conData.caption">{{ conData.caption }}</p>
    </b-overlay>
    <template v-slot:modal-footer v-if="conData && !conData.waiting">
      <div v-if="conData && conData.etherscanURL">
        <b-button @click="openEtherscan()"
                  class="px-7 button-stroked">
          View on etherscan
        </b-button>
      </div>
      <b-button variant="gray"
                v-on:click="hideModal"
                class="mr-3">
        Close
      </b-button>
      <b-button variant="primary"
                v-on:click="confirm"
                v-if="conData && conData.modalType === 'confirm'">
        Confirm
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: {
    conData: {
      title: String,
      modalType: String,
      pool: {
        from: {
          title: String,
          name: String,
          value: String
        },
        to: {
          title: String,
          name: String,
          value: String
        },
      },
      listData: Array,
      caption: String,
      waiting: Boolean,
      etherscanURL: String
    }
  },
  data: function () {
    return {
      fields: ['name', 'data'],
    }
  },
  methods: {
    hideModal() {
      this.$refs['confirmation-modal'].hide();
    },
    confirm() {
      this.$emit('ok', true);
    },
    openEtherscan() {
      window.open(this.conData.etherscanURL || 'https://etherscan.io/', '_blank');
    }
  }
}
</script>

<style scoped lang="scss">

  .caption {
  font-size: $font-size-base;
}

</style>
